import React from 'react';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_month_columns from '../../../../../../../common/src/assets/image/example_month_columns.png';
import template_options_month_columns from '../../../../../../../common/src/assets/image/template_options_month_columns.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The Multiple Months as Columns template style generates multiple months
        where each month is defined as a column and the days of the month are
        defined in rows. This layout can be used to generate an entire year to a
        single page while still showing item details.
      </p>
      <p>
        <Image alt="" src={example_month_columns} />
      </p>
      <h2>Template Settings</h2>
      <p>
        The Multiple Months as Columns template provides the following options
        when creating a calendar...
      </p>
      <Image
        alt=""
        src={template_options_month_columns}
        style={{ width: 448 }}
      />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start output at</strong>
            </td>
            <td>
              Options are <strong>Current month</strong>,{' '}
              <strong>Next month</strong>, or <strong>Selected month</strong>.
              When Selected month is picked, the <strong>Start month</strong>{' '}
              option will be shown.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Start month</strong>
            </td>
            <td>The first month to show in the generated output.</td>
          </tr>
          <tr>
            <td>
              <strong>Number of months</strong>
            </td>
            <td>
              Multiple months can be generated. Each month will be a column in
              the output. You can select how many months to include in the
              output by clicking the drop-down arrow.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time Format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Max items per day</strong>
            </td>
            <td>
              Specifies how many items should fit within a single day. This
              doesn't affect items that span multiple days, which will be
              oriented vertically across multiple rows. It only affects items
              that occur within a single date and are oriented horizontally
              within a single row to provide more space for titles. Increasing
              this value will result in more rows within a date.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item font size</strong>
            </td>
            <td>
              Override the default font size associated with each calendar data
              source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Only use background colors for all-day items</strong>
            </td>
            <td>
              When checked, only events or tasks that are all-day or span
              multiple days will have a background color. Other items will have
              their text colored without a background.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show start time</strong>
            </td>
            <td>
              When checked, event or task start times will be shown to the left
              of their titles.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use bold text for item times</strong>
            </td>
            <td>
              When checked, item titles will be in bold text, to help
              differentiate from the location and description values.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Month-Multiple-Months-as-Columns"
      commentsId="commentsplus_post_174_489"
      title="Multiple Months as Columns Template"
      description="This template consists of multiple months, where each month is defined as a column and the days of the month are defined in rows. This layout can be used to generate an entire year to a single page while still showing item details."
      keywords="multiple month template, multi-month template, month columns"
      content={content}
    />
  );
};

export default Documentation;
